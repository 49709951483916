import React from "react";
import logo from "../assets/popper.png";
import { Helmet } from "react-helmet";

export default function Header() {
  return (
    <>
          <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_LAB_NAME} - Patient Registration Powered By Hummingbird Health</title>
     
        <meta
      name="description"
      content={process.env.REACT_APP_LAB_NAME+"- Patient Registration Powered By Hummingbird Health"}
    />
      </Helmet>

      <div className="headerHolder">
        <div className="lefImage">
          <div>
            <img src={logo} alt="humming bird logo" />
          </div>

          <div>
            {/* <h1> DOH Epidemiology Division (STT) </h1> */}
            {/* <h1> DOH Epidemiology Division (STX) </h1> */}

            <h1> {process.env.REACT_APP_LAB_NAME} </h1>
          </div>
        </div>

        <div className="rightImage">{/* <img src={}/> */}</div>
      </div>
    </>
  );
}
