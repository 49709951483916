import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormGroup from "@material-ui/core/FormGroup";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../assets/bannerImage.png";
import success from "../assets/success.png";
import close from "../assets/close.png";

import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
  root: {
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {
  //reference for scrolling
  const myRef = useRef(null);

  //State for race
  const [raced, setRaced] = React.useState([]);
  //loading state
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [country, setCountry] = React.useState("US");
  const [countryList, setCountryList] = React.useState([]);

  //confirmation code state
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [getQRcode, setQrcode] = React.useState("");
  const [getpdfdownload, setpdfdownload] = React.useState("");

  const [labSiteName, setlabSiteName] = React.useState("");
  const [dob, setdob] = React.useState("");
  const [getdate, setdate] = React.useState("");
  const [gettime, settime] = React.useState("");
  //Temporary state for counties
  const [countyTemp, setCountyTemp] = React.useState([]);

  const [stateSelect, setstateSelect] = React.useState([]);

  //======Get CountryList======//
  const GetCountryList = () => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/bvi/country/list", {})
      .then((response) => {
        if (response) {
          setCountryList(response.data.info);
          // console.log(response.data.info);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  //======Get State List======//
  const GetStateList = () => {
    let data = {
      countryId: "233",
    };

    axios
      .post(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/bvi/state/list" +
          "?countryId=" +
          data.countryId
      )
      .then((response) => {
        if (response) {
          setformState({
            ...formstate,
            ["city"]: "",
          });
          setCounty("");
          let allEntries = Object.entries(response.data.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setstateSelect(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  //======Get CountyList======//
  const GetCountyList = () => {
    let data = {
      stateId: "4966",
    };

    axios
      .post(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/bvi/city/list" +
          "?stateId=" +
          data.stateId
      )
      .then((response) => {
        if (response) {
          let allEntries = Object.entries(response.data.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setCountyTemp(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };


  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setCountyTemp([]);
    countryList.map((item) => {
      if (item.countryCode === event.target.value) {
        let data = {
          countryId: item.id,
        };
        console.log(data);
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT +
              "/api/bvi/state/list" +
              "?countryId=" +
              data.countryId
          )
          .then((response) => {
            if (response) {
              setState("");
              setformState({
                ...formstate,
                ["county"]: "",
                ["city"]: "",
              });
              let allEntries = Object.entries(response.data.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setstateSelect(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  useEffect(() => {
    getRaces();
    GetCountryList();
    GetStateList();
    GetCountyList();
  }, []);

  // api calling for Race
  const getRaces = () => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
        },
      })
      .then((res) => {
        setRaced(res.data.info.races);

        // setCountyTemp(res.data.info.counties["Virgin Islands"]);
        // setstateSelect(Object.entries(res.data.info.states));
      });
  };

  const classes = useStyles();

  //Form Initialization
  const initialFormData = Object.freeze({
    //first accordion
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    zipCode: "",

    //second accordion

    occupation: "",
  });

  // form state

  const [formstate, setformState] = React.useState(initialFormData);

  //Panel state - Accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //move panel
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);

  //symptom date state and Handle change
  const [selectedDateSymptom, setSelectedDateSymptom] = React.useState(null);
  const [dateofbirthError, updateDateofBirthError] = React.useState(null);

  const handleDateChangeSymptom = (date) => {
    setSelectedDateSymptom(date);
  };

  //checkbox symptom initialization

  const initialFormData1 = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Difficulty_breathing: false,
    Headache: false,
    Fatigue: false,
    Diarrhea: false,
    Vomitting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
    Body_and_or_muscle_aches: false,
    Congestion_and_or_runny_nose: false,
  });

  //Checkbox symptom state and Handle Change

  const [stateChecked, setStateCheck] = React.useState(initialFormData1);

  const handleChangeCheck = (event) => {
    setStateCheck({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  // pregnancy radio state and handle change
  const [pregnancy, setPregnancy] = React.useState("U");
  const handleChangeFormPregnancy = (event) => {
    setPregnancy(event.target.value);
  };

  const [symptomatic, setSymptomatic] = React.useState("");
  const handleSymptomatic = (event) => {
    setSymptomatic(event.target.value);

    if (event.target.value === "N") {
      setSelectedDateSymptom(null);
      setStateCheck(initialFormData1);
    }
  };  

  const moveNext = () => {

    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCity("");
    updateCountry("");
    updateCounty("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateState("");
    updateStateChecked("");
    updateErrorEthnicity("");
    updateDateofBirth("");
    updateSymptomDate("");
    seterrorMember("");
    updateErrorSymptomatic("");    
    updateSymptoms("");

    if (formstate.firstName !== "") {
      updateFirstErrorData("");
    }

    if (formstate.lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (formstate.email !== "") {
      updateEmailErrorData("");
    }
    
    if (country !== "") {
      updateCountry("");
    }
    if (county !== "") {
      updateCounty("");
    }
    if (formstate.city !== "") {
      updateCity("");
    }
    if (formstate.zipCode !== "") {
      updateZip("");
    }

    if (state !== "") {
      updateState("");
    }

    if (stateCheckedNA !== "") {
      updateEmailErrorData("");
    }

    //validations
    if (
      formstate.firstName.trim() == "" &&
      formstate.firstName.trim().length == 0
    ) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (
      formstate.lastName.trim() == "" &&
      formstate.lastName.trim().length == 0
    ) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      (stateCheckedNA === false &&
        formstate.email.trim() == "" &&
        formstate.email.trim().length == 0) ||
      stateCheckedNA === ""
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email
      ) &&
      formstate.email != ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    }
   else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    }
    else if (
      formstate.zipCode.trim() === "" &&
      formstate.zipCode.trim().length === 0
    ) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim().length < 5 ||
      formstate.zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    }
    else if (
      countyTemp.length &&
      (county.trim() == "" &&
      county.trim().length == 0)
    ) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      !countyTemp.length &&
     (formstate.city.trim() == "" &&
      formstate.city.trim().length == 0)
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
    } else {
      setExpanded("panel2");
      setActive1(true);

      myRef.current.scrollIntoView();
    }
  };

  //move next panel

  const moveNext1 = () => {

    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCity("");
    updateCountry("");
    updateCounty("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateState("");
    updateStateChecked("");
    updateErrorEthnicity("");
    updateDateofBirth("");
    updateSymptomDate("");
    seterrorMember("");
    updateErrorSymptomatic("");    
    updateSymptoms("");


    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirthError("");
      myRef.current.scrollIntoView();
      // return;
    }

    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }

    if (selectedDate !== null) {
      updateDateofBirth("");
    }

    if (member !== undefined) {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    //validations
    if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirthError("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      // return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    } else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == undefined) {
      seterrorMember("Please select patient group");
      myRef.current.scrollIntoView();
    } else if (member !== "" && formstate.occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
    } else {
      //uncomment
      setExpanded("panel3");

      // setExpanded("panel4");
      setActive2(true);
      myRef.current.scrollIntoView();
    }
  };

  // Whole form Textfield Handle Change event
  const handleChangeForm = (event) => {
    const name = event.target.name;
    let value =event.target.value.replaceAll('"', "'");
    console.log("Form", value.replaceAll("''", "'"))
    setformState({
      ...formstate,
      [name]: value.replaceAll("''", "'"),
    });
  };

  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  //Gender state and select Handle Change
  const [gender, setGender] = React.useState("");

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  //state select
  const [state, setState] = React.useState("VI,Virgin Islands");

  // Load county based on state
  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");
    setState(event.target.value);
    setformState({
      ...formstate,      
      ["city"]: "",
    });
    setCounty("");

    stateSelect.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT +
              "/api/bvi/city/list" +
              "?stateId=" +
              data.stateId
          )
          .then((response) => {
            if (response) {
              let allEntries = Object.entries(response.data.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setCountyTemp(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  //County State and Handle Change
  // const [county, setCounty] = React.useState(process.env.REACT_APP_COUNTY);
  const [county, setCounty] = React.useState("");

  const countyChange = (event) => {
    setCounty(event.target.value);
  };

  //Ethnicity radio
  const [ethnicity, setEthnicity] = React.useState("");
  const handleChangeEthnicity = (event) => {
    setEthnicity(event.target.value);
  };

  //memberGroup state and Handle Change
  const [member, setMember] = React.useState();
  const handleChangeMember = (event) => {
    setMember(event.target.value);
  };

  //date of birth date state and Handle change
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //Not Applicable state and Handle Change

  const [stateCheckedNA, setStateCheckNA] = React.useState(false);

  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  //send mobile chekcox

  const [sendMessageToMobileCheck, setsendMessageToMobileCheck] =
    React.useState(true);

  const handleChangeMobileCheck = (event) => {
    setsendMessageToMobileCheck(event.target.checked);
  };

  //checkbox secondary insurance

  const [newstateChecked, newsetStateCheck] = React.useState(false);

  const handleChangeCheckInsurance = (event) => {
    newsetStateCheck(event.target.checked);
  };

  //race handle change and state
  const [racedSelect, setRacedSelect] = React.useState("");

  const handleChangeRace = (event) => {
    setRacedSelect(event.target.value);
  };

  //modal box open state
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  //modal box close
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const [vaccinated, setVaccinated] = React.useState("");
  const handleVaccinated = (event) => {
    setVaccinated(event.target.value);

    // if(event.target.value==="N"){
    //   setSelectedDateSymptom(null)
    //   setStateCheck(initialFormData1)
    // }
  };

  //modal box body
  const body = (
    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} className="popImage" />
        </div>
        <div className="center">
          <img src={success} className="popImage1" />
        </div>
      </div>

      <div className="successmsg">
        <div style={{ fontSize: "35px", marginTop: "15px" }}>
          Patient registered successfully
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={getQRcode} style={{ width: "160px" }} />
          </div>
          <div style={{ marginTop: "120px" }}>
            <a href={getpdfdownload} target="_blank">
              <i className="fa fa-download"></i>
            </a>
          </div>
        </div>

        <table style={{ display: "inline-grid", paddingBottom: "1.5em" }}>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Confirmation Code:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{confirmationCode}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Name:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {formstate.firstName} {formstate.lastName}
              </div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>DOB:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{dob}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Lab Site:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{labSiteName}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>
                Appointment Created Date and Time(AST):
              </div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {getdate} - {gettime}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  //Error States

  const [errorFirstData, updateFirstErrorData] = React.useState();
  const [errorLastData, updateLastErrorData] = React.useState();
  const [errorMobileData, updateMobileErrorData] = React.useState();
  const [errorEmailData, updateEmailErrorData] = React.useState();
  const [errorAddress, updateAddress] = React.useState();
  const [errorCountry, updateCountry] = React.useState();
  const [errorCounty, updateCounty] = React.useState();
  const [errorCity, updateCity] = React.useState();
  const [errorZip, updateZip] = React.useState();
  const [errorOccupation, updateOccupation] = React.useState();
  const [errorRace, updateRace] = React.useState();
  const [errorGender, updateGender] = React.useState();
  const [errorState, updateState] = React.useState();
  const [errorStateChecked, updateStateChecked] = React.useState();

  const [errorDateofbirth, updateDateofBirth] = React.useState();
  const [errorSymptomDate, updateSymptomDate] = React.useState();
  const [errorSymptoms, updateSymptoms] = React.useState();

  const [errorMember, seterrorMember] = React.useState();
  const [errorEthnicity, updateErrorEthnicity] = React.useState();
  const [errorSymptomatic, updateErrorSymptomatic] = React.useState();

  const [errorVaccinated, updateErrorVaccinated] = React.useState();

  const formSubmit = (e) => {

    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCity("");
    updateCountry("");
    updateCounty("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateState("");
    updateStateChecked("");
    updateErrorEthnicity("");
    updateDateofBirth("");
    updateSymptomDate("");
    seterrorMember("");
    updateErrorSymptomatic("");    
    updateSymptoms("");

    // e.preventDefault();

    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirthError("");
      myRef.current.scrollIntoView();
      // return;
    }

    if (formstate.firstName !== "") {
      updateFirstErrorData("");
    }

    if (formstate.lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (formstate.email !== "") {
      updateEmailErrorData("");
    }
    
    if (country !== "") {
      updateCountry("");
    }
    if (county !== "") {
      updateCounty("");
    }
    if (formstate.city !== "") {
      updateCity("");
    }
    if (formstate.zipCode !== "") {
      updateZip("");
    }   

    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }
    if (state !== "") {
      updateState("");
    }

    if (selectedDate !== null) {
      updateDateofBirth("");
    }

    if (member !== undefined) {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom !== null
    ) {
      updateSymptomDate("");

      myRef.current.scrollIntoView();
    }

    if (stateCheckedNA !== "") {
      updateEmailErrorData("");
    }

    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
      0
    ) {
      updateSymptoms("");
      myRef.current.scrollIntoView();
    }

    if (symptomatic !== null || symptomatic !== "") {
      updateErrorSymptomatic("");
      myRef.current.scrollIntoView();
    }

    if (vaccinated !== null || vaccinated !== "") {
      updateErrorVaccinated("");
      myRef.current.scrollIntoView();
    }

    //validations

    if (formstate.firstName == "" && formstate.firstName.length == 0) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (formstate.lastName == "" && formstate.lastName.length == 0) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      (stateCheckedNA === false &&
        formstate.email.trim() == "" &&
        formstate.email.trim().length == 0) ||
      stateCheckedNA === ""
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email
      ) &&
      formstate.email != ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    }    
    else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state == "" && state.length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim() === "" &&
      formstate.zipCode.trim().length === 0
    ) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim().length < 5 ||
      formstate.zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    } else if (
      countyTemp.length &&
      (county.trim() == "" &&
      county.trim().length == 0)
    ) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      !countyTemp.length &&
      (formstate.city.trim() == "" &&
      formstate.city.trim().length == 0)
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
    } else if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirthError("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    }   else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } 
    //  else if (state == "" && state.length == 0) {
    //   updateState("Please select state");
    //   myRef.current.scrollIntoView();
    // }
    else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == undefined) {
      seterrorMember("Please select patient groups");
      myRef.current.scrollIntoView();
    } else if (member === "Others" && formstate.occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
      return;
    } else if (symptomatic == null || symptomatic == "") {
      updateErrorSymptomatic(
        "Please select if you experience any Covid -19 symptoms?"
      );
      myRef.current.scrollIntoView();
      return;
    } else if (
      symptomatic === "Y" &&
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
        .length === 0
    ) {
      updateSymptoms("Please select Symptoms");
      myRef.current.scrollIntoView();
      return;
    } else if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom == null
    ) {
      updateSymptomDate("Please select Symptom Date");
      myRef.current.scrollIntoView();
      return;
    } else if (vaccinated == null || vaccinated == "") {
      updateErrorVaccinated(
        "Please select if you received the Covid-19 vaccine?"
      );
      myRef.current.scrollIntoView();
      return;
    }

    // Final form Submit check for not null

    // console.log("stateChecked",stateChecked)
    // console.log("Object.keys(stateChecked).filter((e) => stateChecked[e] === true)",Object.keys(stateChecked).filter((e) => stateChecked[e] === true))

    else if (
      formstate.firstName !== "" &&
      formstate.lastName !== "" &&
      newnumber !== "" &&
      // formstate.addressOne !== "" &&
      // formstate.city !== "" &&
      formstate.zipCode !== "" &&
      member !== undefined &&
      gender !== "" &&
      // state !== "" &&
      selectedDate !== null &&
      // selectedDateSymptom !== null &&

      symptomatic !== "" &&
      racedSelect !== "" &&
      ethnicity != ""
    ) {
      var finaldateDob =
        selectedDate.getFullYear() +
        "-" +
        ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + selectedDate.getDate()).slice(-2);
        alertbtn(formstate.firstName.trim(),formstate.lastName.trim(),finaldateDob,newnumber,formstate.email);
    }
  };

  //===Pop Up modal functionalities===//
  const alertbtn = (Fname, Lname, DOB, Phone, Email) => {
    confirmAlert({
      title: 'Please confirm your details',
      message: `Please confirm that your information is correct. Your name and date of birth should be exactly as it appears on your photo ID. This information will be on your result report. Also confirm your phone number and email are valid to receive your result notification.`,
      childrenElement: () => <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <div style={{ margin: "5px 0px" }}>First Name:<span className="Status_name">{Fname}</span></div>
        <div style={{ margin: "5px 0px" }}>Last Name:<span className="Status_name">{Lname}</span></div>
        <div style={{ margin: "5px 0px" }}>DOB (MMM DD, YYYY):<span className="Status_name">{moment(DOB).format("MMM DD, YYYY")}</span></div>
        <div className="modalPhoneNumber" style={{ margin: "-3px 0px", display: "flex", alignItems: "center" }}><div>Phone:</div>
          <PhoneInput
            country={"us"}
            disabled={true}
            required
            name="mobileNumber"
            disableDropdown={true}
            value={Phone}
          /></div>
        <div style={{ margin: "5px 0px" }}>Email:<span className="Status_name">{Email ? Email : "N/A"}</span></div>
        <div style={{ margin: "5px 0px" }}>By clicking confirm, you agree to receiving a text/email notification with your appointment confirmation details.</div>
      </div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun()
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = () => {

    var finaldateDob =
        selectedDate.getFullYear() +
        "-" +
        ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + selectedDate.getDate()).slice(-2);

      if (selectedDateSymptom != null) {
        var finalselectedDateSymptom =
          selectedDateSymptom.getFullYear() +
          "-" +
          ("0" + (selectedDateSymptom.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + selectedDateSymptom.getDate()).slice(-2);
      }
      let splitState = state.split(",");
      let data = {
        ...formstate,

        //misc
        patientId: 0,
        fromWeb: true,

        //first accordion
        state: splitState[0],
        firstName: formstate.firstName.trim(),
        lastName: formstate.lastName.trim(),
        mobileNumber: "+" + newnumber,
        email: stateCheckedNA === true ? "N/A" : formstate.email.trim(),

        //uncomment
        // middleName: formstate.middleName == "" ? "" : formstate.middleName,
        // addressTwo: formstate.addressTwo == "" ? "" : formstate.addressTwo,
        // county: county == "" ? "" : county,

        //new
        middleName:
          formstate.middleName == "" ? null : formstate.middleName.trim(),
        addressTwo:
          formstate.addressTwo == "" ? null : formstate.addressTwo.trim(),
          county: county === "" ? formstate.city.trim() : county,
        addressOne:
          formstate.addressOne == "" ? null : formstate.addressOne.trim(),
        gender: gender == "" ? null : gender,
        occupation:
          formstate.occupation == "" ? null : formstate.occupation.trim(),
        zipCode: formstate.zipCode == "" ? null : formstate.zipCode.trim(),
        city:
          formstate.city.trim() === "" ? county.trim() : formstate.city.trim(),
        country: country,
        phoneCode: "+" + dialCode,
        prioritizedGroup: member === undefined ? null : member,

        //second accordion
        dob: finaldateDob === undefined ? null : finaldateDob,

        ethnicity: ethnicity == "" ? null : ethnicity,

        race: racedSelect == "" ? null : racedSelect,
        sendMessageToMobile: sendMessageToMobileCheck === true ? true : false,

        //third accordion
        symptomaticDate:
          finalselectedDateSymptom === undefined
            ? null
            : finalselectedDateSymptom,

        //uncomment
        pregnant: gender == "Female" ? pregnancy : "U",

        // pregnant: gender == "Female" ? pregnancy : null,
        symptoms:
          Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
            .length == 0
            ? null
            : Object.keys(stateChecked)
                .filter((e) => stateChecked[e] === true)
                .map((index) =>
                  index.replaceAll("_", " ").replaceAll("Fever", "Fever>100.4")
                ),

        symptomatic: symptomatic,
        receivedCovid19Vaccine: vaccinated,
      };

      console.log("data", data);

      setIsLoaded(true);
      //api for posting formFcroix
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT +
            "/api/web/internal/patient/add?labId=" +
            process.env.REACT_APP_LAB_ID,
          data,
          {
            headers: {
              Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode == 200 && res.data.status == "Success") {
            setOpen(true);
            setIsLoaded(false);

            setConfirmationCode(res.data.info.confirmationCode);
            setQrcode(res.data.info.qrCodeUrl);
            setpdfdownload(res.data.info.qrCodePdfUrl);
            setlabSiteName(res.data.info.labSiteName);
            setdob(moment(res.data.info.dob).format("MM/DD/yyyy"));
            console.log(res.data.info);
            setdate(res.data.info.createdDate);
            settime(res.data.info.createdTime);
          } else if (res.data.errorCode == 400) {
            setIsLoaded(false);
            alert(res.data.errorMessage);
          }
        });
  }

  const cancelfun = () => {
    console.log("cancel clicked")
  }

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="accordionHolder">
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="mainer"
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography ref={myRef}>
                    General Information
                    <FormHelperText style={{ color: "red" }}>
                      <span>{errorFirstData !== "" ? errorFirstData : ""}</span>

                      <span> {errorLastData ? errorLastData : ""}</span>
                      <span> {errorMobileData ? errorMobileData : ""}</span>
                      <span> {errorEmailData ? errorEmailData : ""}</span>
                      <span> {errorAddress ? errorAddress : ""}</span>
                      <span> {errorCity ? errorCity : ""}</span>
                      <span> {errorCountry ? errorCountry : ""}</span>
                      <span> {errorCounty ? errorCounty : ""}</span>
                      <span> {errorZip ? errorZip : ""}</span>
                      <span> {errorState ? errorState : ""}</span>
                    </FormHelperText>
                  </Typography> 
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic1"
                      label="First Name "
                      autoComplete="new-password"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="firstName"
                      value={formstate.firstName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic2"
                      label="Middle Initial"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="middleName"
                      autoComplete="new-password"
                      value={formstate.middleName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic3"
                      label="Last Name"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="lastName"
                      autoComplete="new-password"
                      value={formstate.lastName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Please share your contact information. Your email and
                      phone will also be where you will receive an invitation to
                      view your test results.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={7} sm={12}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ paddingBottom: "0.3em" }}
                    >
                      Mobile Number *
                    </InputLabel>
                    <PhoneInput
                      country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={mobileNumberChange}
                      value={newnumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendMessageToMobileCheck}
                          onChange={handleChangeMobileCheck}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="This number can receive text messages"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={7} sm={12}>
                    <TextField
                      id="standard-basic4"
                      label="Email"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="email"
                      autoComplete="new-password"
                      value={stateCheckedNA === true ? "N/A" : formstate.email}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={stateChecked.Shortness_of_breath}
                          onChange={handleChangeNA}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="N/A - Not Applicable"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic5"
                      label="Address 1"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      // required
                      onChange={handleChangeForm}
                      name="addressOne"
                      autoComplete="new-password"
                      value={formstate.addressOne}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic6"
                      label="Address 2"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="addressTwo"
                      autoComplete="new-password"
                      value={formstate.addressTwo}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Country *</InputLabel>
                      <Select
                        name="country"
                        value={country}
                        onChange={handleChangeCountry}
                        // required
                      >
                        {countryList.map((item) => (
                          <MenuItem value={item.countryCode}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        State/Territory *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="state"
                        value={state}
                        onChange={handleChangeState}
                        // required
                      >
                        {stateSelect.map((item) => (
                          <MenuItem value={item[1].stateCode + "," + item[0]}>
                            {item[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic8"
                      label="Zipcode/Postal Code"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="zipCode"
                      type="number"
                      autoComplete="new-password"
                      value={formstate.zipCode}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  {countyTemp.length ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          County/District *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label11"
                          id="demo-simple-select"
                          onChange={countyChange}
                          value={county}
                        >
                          {countyTemp !== null && countyTemp !== undefined
                            ? countyTemp.map((item) => (
                                <MenuItem value={item[0]}>{item[0]}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic8"
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        label="City/Estate*"
                        variant="standard"
                        name="city"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        value={formstate.city}
                        onChange={handleChangeForm}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12} sm={12}>
                    <div
                      className={active1 === true ? "buttonNext" : "buttonNext"}
                    >
                      <button onClick={moveNext}>Next</button>
                    </div>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    Additional Demographics
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorOccupation ? errorOccupation : ""}</span>
                      <span> {errorRace ? errorRace : ""}</span>
                      <span> {errorGender ? errorGender : ""}</span>
                      <span> {errorDateofbirth ? errorDateofbirth : ""}</span>
                      <span> {errorMember ? errorMember : ""}</span>
                      <span> {errorEthnicity ? errorEthnicity : ""}</span>

                      <span> {dateofbirthError ? dateofbirthError : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={6} sm={12}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format="MM/dd/yyyy"
                        label={
                          <Typography variant="inherit" component="span">
                            {" "}
                            Date of Birth{" "}
                            <span className="adjs">(MM/DD/YYYY) </span>
                          </Typography>
                        }
                        maxDate={new Date()}
                        required
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Race *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={racedSelect}
                        onChange={handleChangeRace}
                        name="race"
                        required
                      >
                        {raced.map((index) => (
                          <MenuItem value={index.raceName}>
                            {index.raceName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Gender *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        onChange={handleChangeGender}
                        required
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                        {/* <MenuItem value={"Unknown"}>Unknown</MenuItem> */}
                        <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12} className="radioAwesome">
                    <FormLabel component="legend">Ethnicity *</FormLabel>
                    <RadioGroup
                      aria-label="ethnicity"
                      name="ethnicity"
                      value={ethnicity}
                      onChange={handleChangeEthnicity}
                    >
                      <FormControlLabel
                        value="Hispanic"
                        control={<Radio />}
                        label="Hispanic"
                      />
                      <FormControlLabel
                        value="Non-Hispanic"
                        control={<Radio />}
                        label="Non-Hispanic"
                      />

                      <FormControlLabel
                        value="Unknown"
                        control={<Radio />}
                        label="Unknown"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Is the patient a member of one of these groups? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="member"
                      name="member"
                      value={member}
                      onChange={handleChangeMember}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="First response/Healthcare"
                          control={<Radio />}
                          label="First responder or Healthcare worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Congregate facility"
                          control={<Radio />}
                          label="Congregate facility worker or resident"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Hospitality/Transportation"
                          control={<Radio />}
                          label="Hospitality or Transportation worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="In-person school/daycare"
                          control={<Radio />}
                          label="In-person school/daycare student or worker"
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not reported"
                          control={<Radio />}
                          label="Not reported"
                        />
                      </Grid> */}

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not part of any listed groups"
                          control={<Radio />}
                          label="Not part of any listed groups"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Others"
                          control={<Radio />}
                          label="Other priority group (Please indicate below)"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {member ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic10"
                        label="Occupation/Affiliation *"
                        style={{ width: "100%" }}
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        // required
                        onChange={handleChangeForm}
                        name="occupation"
                        autoComplete="new-password"
                        value={formstate.occupation}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <div
                    className={active2 === true ? "buttonNext" : "buttonNext"}
                  >
                    <button onClick={moveNext1}>Next</button>
                  </div>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    Recent Health Updates
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorStateChecked ? errorStateChecked : ""}</span>
                      <span> {errorSymptomDate ? errorSymptomDate : ""}</span>
                      <span> {errorSymptoms ? errorSymptoms : ""}</span>
                      <span> {errorSymptomatic ? errorSymptomatic : ""}</span>
                      <span> {errorVaccinated ? errorVaccinated : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                {gender == "Female" ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Pregnancy Status
                    </FormLabel>
                    <RadioGroup
                      aria-label="pregnancy"
                      name="pregnancy"
                      value={pregnancy}
                      onChange={handleChangeFormPregnancy}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />

                      <FormControlLabel
                        value="U"
                        control={<Radio />}
                        label="Unknown"
                      />
                    </RadioGroup>
                  </Grid>
                ) : null}

                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Have you been experiencing any Covid-19 symptoms? *
                      </FormLabel>
                      <RadioGroup
                        aria-label="symtomatic"
                        name="symtomatic"
                        value={symptomatic}
                        onChange={handleSymptomatic}
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>

                    {symptomatic === "Y" ? (
                      <FormGroup className="adjCheck">
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Shortness_of_breath}
                                onChange={handleChangeCheck}
                                name="Shortness_of_breath"
                                value="Shortness of breath"
                              />
                            }
                            label="Shortness of breath"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Cough}
                                onChange={handleChangeCheck}
                                name="Cough"
                                value="Cough"
                              />
                            }
                            label="Cough"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Chills}
                                onChange={handleChangeCheck}
                                name="Chills"
                                value="Chills"
                              />
                            }
                            label="Chills"
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Fever}
                                onChange={handleChangeCheck}
                                name="Fever"
                                value="Fever >100.4"
                              />
                            }
                            label="Fever >100.4"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Difficulty_breathing}
                                onChange={handleChangeCheck}
                                name="Difficulty_breathing"
                                value="Difficulty breathing"
                              />
                            }
                            label="Difficulty breathing"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Headache}
                                onChange={handleChangeCheck}
                                name="Headache"
                                value="Headache"
                              />
                            }
                            label="Headache"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Fatigue}
                                onChange={handleChangeCheck}
                                name="Fatigue"
                                value="Fatigue"
                              />
                            }
                            label="Fatigue"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Diarrhea}
                                onChange={handleChangeCheck}
                                name="Diarrhea"
                                value="Diarrhea"
                              />
                            }
                            label="Diarrhea"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Vomitting}
                                onChange={handleChangeCheck}
                                name="Vomitting"
                                value="Vomitting"
                              />
                            }
                            label="Vomitting"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Sore_Throat}
                                onChange={handleChangeCheck}
                                name="Sore_Throat"
                                value="Sore Throat"
                              />
                            }
                            label="Sore Throat"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Nausea}
                                onChange={handleChangeCheck}
                                name="Nausea"
                                value="Nausea"
                              />
                            }
                            label="Nausea"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Loss_of_taste}
                                onChange={handleChangeCheck}
                                name="Loss_of_taste"
                                value="Loss of taste"
                              />
                            }
                            label={
                              <Typography
                                variant="inherit"
                                component="span"
                                style={{ paddingTop: "1em" }}
                              >
                                Loss of taste <br /> and / or smell{" "}
                              </Typography>
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Body_and_or_muscle_aches}
                                onChange={handleChangeCheck}
                                name="Body_and_or_muscle_aches"
                                value="Body and/or muscle aches"
                              />
                            }
                            label="Body and/or muscle aches"
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  stateChecked.Congestion_and_or_runny_nose
                                }
                                onChange={handleChangeCheck}
                                name="Congestion_and_or_runny_nose"
                                value="Congestion and/or runny nose"
                              />
                            }
                            label="Congestion and/or runny nose"
                          />
                        </Grid>
                      </FormGroup>
                    ) : null}
                  </FormControl>
                </Grid>

                {symptomatic === "Y" &&
                Object.keys(stateChecked).filter(
                  (e) => stateChecked[e] === true
                ).length > 0 &&
                stateChecked !== undefined ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sm={12}
                      style={{ marginTop: "1em" }}
                    >
                      <KeyboardDatePicker
                        clearable
                        value={selectedDateSymptom}
                        onChange={(date) => handleDateChangeSymptom(date)}
                        format="MM/dd/yyyy"
                        label="Symptom start date"
                        maxDate={new Date()}
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : null}

                <Grid item xs={12} md={12} sm={12}>
                  <FormLabel component="legend" className="paddingAdj">
                    Have you received the Covid-19 vaccine? *
                  </FormLabel>
                  <RadioGroup
                    aria-label="symtomatic"
                    name="symtomatic"
                    value={vaccinated}
                    onChange={handleVaccinated}
                  >
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="Yes, I received the vaccine"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="No, I have not yet received the vaccine"
                    />
                  </RadioGroup>
                </Grid>

                {/* <Grid item xs={12} md={12} sm={12}>
                  <div
                    className={active3 === true ? "buttonNext" : "buttonNext"}
                  >
                    <button onClick={moveNext2}>Next</button>
                  </div>
                </Grid> */}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div className="submit">
            <button className="btn" onClick={formSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}
